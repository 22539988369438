import React, { useState, useEffect } from "react";
import Loading from "../components/Loading";

/**
 * Fetch the latest posts from a WordPress blog
 * using the React Hooks, "useState" and "useEffect".
 *
 * Hooks let us use state and other React features
 * without having to write a React class.
 */
const BlogCards = () => {
    /**
     * Declare new state variables and "hook" into useState().
     *
     * @posts     The current state.
     * @setPosts  A callback function that updates "posts".
     * @link      https://reactjs.org/docs/hooks-state.html
     */
    const [posts, setPosts] = useState([]);
    const [isLoading, setisLoading] = useState(true);

    /**
     * useEffect() is similar to componentDidMount or componentDidUpdate
     * and will update "setPosts". Here is where we'll fetch the data
     * and update our state.
     *
     * @link https://reactjs.org/docs/hooks-effect.html
     */
    useEffect(
        /**
         * This is an effect function, It must not return anything besides
         * a function which is used for clean-up.
         *
         * We'll write our fetch data function inside, and call it immediately.
         */
        () => {
            /**
             * This is a common way to fetch data from an REST-API using fetch(). Using
             * async/await basically acts as syntactic sugar on top of promises, making
             * asynchronous code easier to write and to read afterwards.
             *
             * @link https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
             * @link https://developer.mozilla.org/en-US/docs/Web/API/Body/json
             * @link https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Asynchronous/Async_await
             */
            async function fetchData() {

                try {
                    const response = await fetch(
                        "https://link-router-epbd.netlify.app/eduportalbd/data/uni_adm.json"
                    );
                    const data = await response.json();
                    setPosts(data); // Set state using our "setPosts" callback.
                    setisLoading(false); // Set state using our "setisLoading" callback.

                } catch (error) {

                    setisLoading(false); // Set state using our "setisLoading" callback.

                }


            }
            fetchData(); // Immediately run our fetch function.
        },
        [] // Our effect doesn't need props and we only want to fetch data when the component mounts. Plus, this empty array prevents an infinite loop.
    );

    /**
     * The posts have been fetched, and we can display them
     * by returning JSX to describe what our component should
     * look like.
     *
     * @link https://reactjs.org/docs/introducing-jsx.html
     */

    // open openInAppBrowser
    const openInAppBrowser = async (externalUrl, GAEventName, GAparam) => {
        await window.open(externalUrl, '_blank');
    };
    // open openInAppBrowser
    const sortedPosts = posts.sort((a, b) => parseInt(b.key_id) - parseInt(a.key_id))
    return (
        <>
            <div className="container mx-auto">
                <p className='text-xl my-6 ml-3 md:ml-0 font-bold'>বিশ্ববিদ্যালয় ভর্তি তথ্য ২০২২
                </p>
                <div className="text-sm border border-blue-500 rounded-lg p-3 m-2 bg-blue-100" onClick={() => openInAppBrowser("https://eduportalbd.com/category/admission/#gsc.tab=0")} >সকল বিশ্ববিদ্যালয় ভর্তি তথ্য দেখতে এখানে ক্লিক করুন। অথবা নিচের লিষ্ট থেকে বেছে নিন।</div>
            </div>
            {isLoading ? <Loading /> : null}
            <div className="container mx-auto px-2 lg:px-0 text-left">
                {/* Loop through and display posts */}
                {sortedPosts.map((post) => (
                    <div className="px-1 mb-2" key={post.key_id}>
                        <article className="w-full rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out p-4 border-t-4 border-blue-500">
                            <div onClick={() => openInAppBrowser(post.link, "Home: Latest New Clicked = "+post.title)} target="_blank" rel="noreferrer" className="">
                                <h1 className="text-base font-semibold mb-3 mt-0" > {post.title} </h1>
                                <div className="text-sm text-gray-600" >{post.excerpt} </div>
                            </div>
                        </article>
                    </div>
                ))}
            </div>
        </>
    );
};

export default BlogCards;

// Further reading
// https://www.robinwieruch.de/react-hooks-fetch-data
// https://reactjs.org/docs/hooks-faq.html#how-can-i-do-data-fetching-with-hooks
